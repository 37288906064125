<template>
    <UserHeader />

    <div v-if="loggedIn" class="page-bg">
        <main class="page-wrap">
            <!-- *** where the router component is placed *** -->
            <RouterView :key="$route.path" />
        </main>

        <div v-if="loggedIn && rootStore.currentCompany?.readonly"
             class="lg:max-w-11/12 xl:max-w-7xl mx-auto bg-white pb-4 text-center text-xs text-gray-500">
            {{ rootStore.currentCompany?.companyName }} is a read-only company.
        </div>
    </div>
    <main v-else class="w-full pb-24 page-bg">
        <!-- *** where the router component is placed *** -->
        <RouterView :key="$route.path" />
    </main>

    <UserFooter />
</template>

<script setup>
import { useHead } from '@unhead/vue';
import UserHeader from './header.vue';
import UserFooter from './footer.vue';
import { computed } from 'vue';
import { useRootStore } from '@/user/stores/root';

const rootStore = useRootStore();

useHead({
    title: 'TRAX',
    titleTemplate: '%s | TRAX',
});

const loggedIn = computed(() => rootStore.loggedIn);
</script>
