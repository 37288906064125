import { createRouter, createWebHistory } from 'vue-router';
import { scrollBehavior, parseQuery, stringifyQuery, logPageView, beforeEach } from '@/common/router_helpers';
import { useRootStore } from '@/user/stores/root';
import Voter from '@/common/classes/voter';

const checkForValidCompanyGuard = (to) => {
    const voter = new Voter();

    if (!voter.hasValidCompany()) {
        alert(voter.getValidCompanyMessage());

        return 'project-add' === to.name ? { name: 'dashboard' } : false;
    }
};
const checkCompanyModifyGuard = (to) => {
    checkForValidCompanyGuard(to);

    const voter = new Voter();

    if (!voter.canCompanyModify()) {
        return { name: 'dashboard' };
    }
};

const router = createRouter({
    history: createWebHistory(),

    routes: [
        // *********************************************************************
        // PUBLIC
        // *********************************************************************
        {
            name: 'login',
            path: '/login',
            component: () => import('./login'),
        },
        {
            path: '/recover',
            component: () => import('./user_recover'),
            children: [
                { path: '', redirect: '/recover/initiate' },
                {
                    name: 'user-recover-initiate',
                    path: 'initiate',
                    component: () => import('./user_recover/initiate'),
                },
                {
                    name: 'user-recover-reset',
                    path: 'reset',
                    component: () => import('./user_recover/reset'),
                },
            ],
        },
        {
            name: 'user-activate',
            path: '/activate',
            component: () => import('./user_activate'),
        },
        {
            name: 'user-verify',
            path: '/verify',
            component: () => import('./user_verify'),
        },

        // *********************************************************************
        // LOGGED IN
        // *********************************************************************
        {
            path: '/dashboard',
            name: 'dashboard',
            alias: '/',
            component: () => import('./dashboard'),
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },
        {
            path: '/project',
            component: () => import('./project'),
            children: [
                {
                    name: 'project-view',
                    path: ':projectId/view',
                    component: () => import('./project/view'),
                    props: (route) => ({
                        projectId: route.params.projectId,
                        jobId: route.query['job-id'],
                    }),
                },
                {
                    name: 'project-add',
                    path: 'add',
                    component: () => import('./project/add'),
                    props: (route) => ({
                        encirclePdfId: route.query['encircle-pdf-id'],
                    }),
                    beforeEnter: checkCompanyModifyGuard,
                },
                {
                    name: 'project-edit',
                    path: ':projectId/edit',
                    component: () => import('./project/edit'),
                    props: (route) => ({
                        projectId: route.params.projectId,
                        // this is so we can say "loading claim" vs "loading project"
                        isInsuranceClaim: 1 === +route.query.claim,
                    }),
                    beforeEnter: checkCompanyModifyGuard,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },
        {
            path: '/project/:projectId/company',
            component: () => import('./project_company'),
            children: [
                {
                    name: 'project-company-add',
                    path: 'add',
                    component: () => import('./project_company/add'),
                    props: (route) => ({
                        projectId: route.params.projectId,
                        companyId: route.query['company-id'],
                        projectRole: route.query['project-role'],
                        projectRoleOther: route.query['project-role-other'],
                    }),
                    beforeEnter: checkCompanyModifyGuard,
                },
                {
                    name: 'project-company-edit',
                    path: ':projectCompanyId/edit',
                    component: () => import('./project_company/edit'),
                    props: true,
                    beforeEnter: checkCompanyModifyGuard,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },
        {
            path: '/project/:projectId/job/:jobId/trip',
            component: () => import('./trip'),
            children: [
                {
                    name: 'trip-add',
                    path: 'add',
                    component: () => import('./trip/add'),
                    props: (route) => ({
                        projectId: route.params.projectId,
                        jobId: route.params.jobId,
                        encirclePdfId: route.query['encircle-pdf-id'],
                    }),
                    beforeEnter: checkCompanyModifyGuard,
                },
                {
                    name: 'trip-edit',
                    path: ':tripId/edit',
                    component: () => import('./trip/edit'),
                    props: true,
                    beforeEnter: checkCompanyModifyGuard,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },
        {
            path: '/project/:projectId/job',
            component: () => import('./job'),
            children: [
                {
                    name: 'job-add',
                    path: 'add',
                    component: () => import('./job/add'),
                    props: (route) => ({
                        projectId: route.params.projectId,
                        encirclePdfId: route.query['encircle-pdf-id'],
                    }),
                    beforeEnter: checkCompanyModifyGuard,
                },
                {
                    name: 'job-edit',
                    path: ':jobId/edit',
                    component: () => import('./job/edit'),
                    props: true,
                    beforeEnter: checkCompanyModifyGuard,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },

        {
            path: '/reports',
            name: 'reports',
            component: () => import('./report'),
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },
        {
            path: '/report/:reportName',
            name: 'report',
            component: () => import('./report/view'),
            props: true,
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },
        {
            path: '/profile',
            component: () => import('./profile_edit'),
            redirect: '/profile/edit',
            children: [
                {
                    name: 'user-profile-edit',
                    path: 'edit',
                    component: () => import('./profile_edit/profile'),
                },
                {
                    name: 'user-profile-edit-password',
                    path: 'password',
                    component: () => import('./profile_edit/password'),
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },

        {
            path: '/pattern-library',
            name: 'pattern-library',
            component: () => import('./pattern_library'),
            meta: {
                requiresAuth: true,
                role: 'ROLE_SUPER_ADMIN',
            },
        },



        // *********************************************************************
        // ERRORS
        // *********************************************************************
        {
            path: '/:pathMatch(.*)*',
            name: '404',
            component: () => import('./error/404'),
        },
        {
            path: '/:pathMatch(.*)*',
            name: '403',
            component: () => import('./error/403'),
        },
    ],

    scrollBehavior,
    parseQuery,
    stringifyQuery,
});

router.beforeEach(beforeEach(router.resolve({ name: 'login' }).href, useRootStore, 'user'));

router.afterEach((to) => {
    logPageView(to, useRootStore);
});

export default router;
